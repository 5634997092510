<template>
  <div class="container-fluid">
    <h3 class="text-light mt-3">Contactos</h3>
    <div class="row">
      <div v-if="!btn" class="col-12">
        <ContactoTables :lstContacto="lstContacto" />
      </div>
      <div v-if="btn" class="col-12"><ContactoForm /></div>
    </div>
  </div>
</template>

<script>
import { computed, inject, provide, reactive } from "vue-demi";
import ContactoTables from "../components/Table/ContactoTables.vue";
import ContactoForm from "../components/Form/ContactoForm.vue";
import { useStore } from "vuex";
export default {
  components: {
    ContactoTables,
    ContactoForm
  },
  setup() {
    const btn = inject("btn");
    const store = useStore();
    const objContacto = reactive({
      id: null,
      telefono: "",
      whatsapp: "",
      correo: "",
      descripcion: "",
      deleted: false,
    });

    let _lstContacto = {
      url: "/api/v1/getContacto",
      set: "setContacto",
    };
    store.dispatch("getData", _lstContacto);

    const lstContacto = computed(() => store.state.contacto);    

    provide("objContacto", objContacto);

    return { btn, lstContacto };
  },
};
</script>

<style>
</style>