<template>
  <form @submit.prevent="submitContacto" class="contacto">
    <div class="text-end text-light">
      <Cerrar />
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <label class="text-light">Teléfono</label>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="4"
          v-model="objContacto.telefono"
        ></textarea>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <label class="text-light">Whatsapp</label>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="4"
          v-model="objContacto.whatsapp"
        ></textarea>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <label class="text-light">Correo</label>        
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="4"
          v-model="objContacto.correo"
        ></textarea>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <label class="text-light">Descripcion</label>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="4"
          v-model="objContacto.descripcion"
        ></textarea>
      </div>
    </div>

    <div class="row justify-content-center mt-4">
      <div class="col-md-2">
        <button class="myButtonAceptar">Guardar</button>
      </div>
    </div>
  </form>
</template>

<script>
import { inject, onBeforeUnmount } from "vue-demi";
import Cerrar from "../button/Cerrar.vue";
import { useStore } from "vuex";
export default {
  components: {
    Cerrar,
  },
  setup() {
    const objContacto = inject("objContacto");
    const store = useStore();
    const btn = inject("btn");

    const submitContacto = async () => {
      let obj = "";
      if (objContacto.id) {
        obj = { url: "/api/v1/editContacto", data: objContacto };
      } else {
        obj = { url: "/api/v1/createContacto", data: objContacto };
      }

      await store.dispatch("createPost", obj);
      let _objContacto = {
        url: "/api/v1/getContacto",
        set: "setContacto",
      };

      await store.dispatch("getData", _objContacto);
      btn.value = false;
    };

    onBeforeUnmount(() => {
      objContacto.id = null;
      objContacto.telefono = "";
      objContacto.whatsapp = "";
      objContacto.correo = "";
      objContacto.descripcion = "";
      objContacto.deleted = false;
      btn.value = false;
    });

    return { submitContacto, objContacto };
  },
};
</script>

<style>
.contacto {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
  width: 40em;
  margin: auto;
}

@media (max-width: 1000px) {
  .contacto {
    width: 100%;
  }
}
</style>