<template>
  <div class="text-end mt-2 mb-2">
    <!-- <button class="myButtonAceptar" @click="agregarContacto">Agregar</button> -->
  </div>

  <div class="card table-responsive-sm">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Teléfono</th>
          <th scope="col">Whatsapp</th>
          <th scope="col">Correo</th>
          <th scope="col">Descripcion</th>          
          <!-- <th scope="col">Eliminar</th> -->
        </tr>
      </thead>
      <tbody v-for="c of lstContacto" v-bind:key="c.id">
        <tr style="cursor: pointer">
          <td @click="select(c)">{{ c.telefono }}</td>
          <td @click="select(c)">{{ c.whatsapp }}</td>
          <td @click="select(c)">{{ c.correo }}</td>
          <td @click="select(c)">{{ c.descripcion }}</td>        
          <!-- <td>
            <button class="btnEliminarTable" @click="eliminarContacto(c)">
              <i style="color: #fff" class="far fa-trash-alt"></i>
            </button>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject } from "vue-demi";
import { useStore } from "vuex";
import Swal from "sweetalert2";
export default {
  props: ["lstContacto"],
  setup() {
    const objContacto = inject("objContacto");
    const btn = inject("btn");
    const store = useStore();

    const agregarContacto = () => {
      objContacto.id = null;
      objContacto.telefono = "";
      objContacto.whatsapp = "";
      objContacto.correo = "";
      objContacto.descripcion = "";
      objContacto.deleted = false;
      btn.value = true;
    };

    const select = (e) => {
      objContacto.id = e.id;
      objContacto.telefono = e.telefono;
      objContacto.whatsapp = e.whatsapp;
      objContacto.correo = e.correo;
      objContacto.descripcion = e.descripcion;
      objContacto.deleted = e.deleted;
      btn.value = true;
    };

    const eliminarContacto = (e) => {
      Swal.fire({
        title: "¿Seguro que desea eliminar este registro?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          objContacto.id = e.id;
          objContacto.telefono = e.telefono;
          objContacto.whatsapp = e.whatsapp;
          objContacto.correo = e.correo;
          objContacto.descripcion = e.descripcion;
          objContacto.deleted = true;

          let objEditarContacto= {
            url: "/api/v1/editContacto",
            data: objContacto,
            nomsj: true,
          };

          await store.dispatch("createPost", objEditarContacto);

          let _objContacto = {
            url: "/api/v1/getContacto",
            set: "setContacto",
          };
          await store.dispatch("getData", _objContacto);

          return true;
        }
      });
    };

    return { agregarContacto, select, eliminarContacto };
  },
};
</script>

<style>
</style>